import { setAuthorization } from "./general"
import Axios from 'axios'

const baseUrl = process.env.VUE_APP_BASE_URL;

export async function login(credentials) {
    try {
        let response = await Axios.post(baseUrl + 'login', credentials);
        setAuthorization(response.data.tokenData.token);
        return response.data;
    } catch (e) {
        throw new Error(e.response?.data?.message || 'Wrong email or password');
        // return e.response?.data?.message || 'Wrong email or password';
    }
}

export function getLocalUser(){
    const userStr = localStorage.getItem('userKilat')

    if(!userStr){
        return null
    }

    return JSON.parse(userStr)
}

export function getToken(){
    const token = localStorage.getItem('tokenKilat')

    if(!token){
        return null;
    }

    return token;
}

export function getRefToken(){
    const tokenRef = localStorage.getItem('tokenRefKilat')

    if(!tokenRef){
        return null;
    }

    return tokenRef;
}
