import Vue from 'vue'
window.Vue = require('vue');

Vue.filter('amountFormat', function (num) {
    let numToInt =  parseInt(num)
// return numToInt
    if(num){
        return 'Rp ' + numToInt.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return 'Rp 0,00';

});

Vue.filter('amountFormatNoPrefix', function (num) {
    let numToInt =  parseInt(num)
// return numToInt
    if(num){
        return numToInt.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return '0,00';

});

Vue.filter('trxFormat', function (num) {
    let numToInt =  parseInt(num)
// return numToInt
    if(num){
        return numToInt.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    return '0';

});
