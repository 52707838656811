<template>
  <v-row dense>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ msg.newMsg }}
          <v-spacer></v-spacer>
          <v-btn
              v-if="action.toLowerCase() === 'back'"
              depressed
              color="red"
              dark
              @click="$router.go(-1)"
          >
            {{ msg.newUser }}
          </v-btn>

          <v-btn
              v-else-if="action.toLowerCase() === 'non'"
              class="d-none"
          >
            {{ msg.newUser }}
          </v-btn>

          <v-btn
              v-else
              depressed
              color="primary"
              dark
              @click="goTONew"
          >
            {{ msg.newUser }}
          </v-btn>


        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RowHeader",
  data: () => ({
    // action: null,
    // msg: null,
  }),
  props: {
    action: String,
    msg: Object,
  },
  methods : {
    goTONew () {
      this.$router.push({ name: this.msg.actionName, params: {} });
    },
  }
}
</script>

<style scoped>

</style>
