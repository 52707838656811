export function TrxData() {
  return {
    from_date: '',
    to_date: '',
    category_code: '',
    merchant_code: '',
    user_id: '',
    status: '',
    type: '01',
  }
}

export function DepositData() {
  return {
    tx_id: '',
    amount: '',
    img: null,
    status: '',
  }
}

export function StatusList() {
  return [
    { value: '0', text: 'Success' },
    { value: '1', text: 'Advice' },
    { value: '2', text: 'Failed' },
    { value: '3', text: 'Inquiry' },
    { value: '4', text: 'Success with settlement' },
    { value: '5', text: 'waiting callback' },
  ]
}
