import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Axios from 'axios';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import moment from 'moment';
import store from './store/index';
require('./store/helpers/formater');

Vue.config.productionTip = false

Vue.prototype.$http = Axios;
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL;
Vue.prototype.$moment = moment;
Vue.prototype.$secretCode = process.env.VUE_APP_SECRET_CODE;
Vue.prototype.action = new Vue();

const token = localStorage.getItem('tokenKilat');

if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  Vue.prototype.$http.defaults.headers.common["Content-Type"] = 'application/json'
  Vue.prototype.$http.defaults.timeout = 15000
  Vue.prototype.$http.defaults.headers.common["Accept"] = 'application/json'
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
