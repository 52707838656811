<template>
  <v-container v-if="$store.getters.isLoggedIn">
    <row-header :msg="msgRowHeader" action="non"></row-header>

    <v-row dense>
      <v-col cols="12" md="4" sm="6">
        <v-card
            color="green"
            dark
        >
          <v-card-title class="headline">Current Balance</v-card-title>
          <v-card-subtitle>Last Balance</v-card-subtitle>
          <v-card-actions>
            <v-btn text>
              <span>&nbsp;</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text>
              <span>{{ current_balance | amountFormat }}</span>
              <span>(Trx)</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <v-card
            color="#e91e63"
            dark
        >
          <v-card-title class="headline">Total Trx This Year</v-card-title>
          <v-card-subtitle>Transaksi Until {{ this.dateNow }}</v-card-subtitle>
          <v-card-actions>
            <v-btn text>
              <span>&nbsp;</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text>
              <span>{{ trxYear }}</span>
              <span>(Trx)</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <v-card
            color="cyan darken-1"
            dark
        >
          <v-card-title class="headline">Total Trx This Month</v-card-title>
          <v-card-subtitle>Transaksi Until {{ this.dateNow }}</v-card-subtitle>
          <v-card-actions>
            <v-btn text>
              <span>&nbsp;</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text>
              <span>{{ trxMonth }}</span>
              <span>(Trx)</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <v-card
            color="light-blue darken-1"
            dark
        >
          <v-card-title class="headline">Total Trx This Day</v-card-title>
          <v-card-subtitle>Transaksi Until {{ this.dateNow }}</v-card-subtitle>
          <v-card-actions>
            <v-btn text>
              <span>&nbsp;</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text>
              <span>{{ trxDay }}</span>
              <span>(Trx)</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" v-if="role === 1" sm="6">
        <v-card
            color="teal darken-1"
            dark
        >
          <v-card-title class="headline">Total Users</v-card-title>
          <v-card-subtitle>
            <br />
          </v-card-subtitle>
          <v-card-actions>
            <v-btn text>
              <span>&nbsp;</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text>
              <span>{{ totalUsers }}</span>
              <span>(Users)</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" v-if="role === 1" sm="6">
        <v-card
            color="light-green darken-3"
            dark
        >
          <v-card-title class="headline">Total Agent</v-card-title>
          <v-card-subtitle><br /></v-card-subtitle>
          <v-card-actions>
            <v-btn text>
              <span>&nbsp;</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text>
              <span>{{ totalAgent }}</span>
              <span>(Agent)</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" v-if="role <= 2" sm="6">
        <v-card
            color="lime darken-4"
            dark
        >
          <v-card-title class="headline">Total Trx Locket</v-card-title>
          <v-card-subtitle><br /></v-card-subtitle>
          <v-card-actions>
            <v-btn text>
              <span>&nbsp;</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text>
              <span>{{ totalLocket }}</span>
              <span>(Locket)</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-overlay
        :absolute="absolute"
        :value="overlay"
    >
      <v-card color="white">
        <v-card-text>
          <overlay-img></overlay-img>
        </v-card-text>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import RowHeader from "@/components/content/RowHeader";
import OverlayImg from "../../components/content/OverlayImg";

const {TrxData} = require("../../store/services/TrxModel");
const {GetRequest, RequestToken} = require("../../store/services/users");

export default {
  name: "HomeComponent",
  components: {
    RowHeader,
    OverlayImg,
  },
  data: () => ({
    msgRowHeader: {newUser: '', newMsg: 'Dashboard'},
    dateNow: null,
    amountYear: 0,
    amountMonth: 0,
    amountDay: 0,
    trxYear: 0,
    trxMonth: 0,
    trxDay: 0,
    totalUsers: 0,
    totalAgent: 0,
    totalLocket: 0,
    reqParams: {},
    role: null,
    trx_model: new TrxData(),
    overlay: false,
    absolute: false,
    isRefresh: false,
    current_balance: 0,
  }),
  async mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.role = this.$store.getters.currentUser.role.level;
      this.dateNow = this.$moment().format('DD MMM YYYY');
      await this.getTrxData();
      if (!this.isRefresh) {
        await this.getTrxDataMonth();
        await this.getTrxDataDay();
        if (this.role === 1) {
          await this.getUsersData();
          await this.getUsersDataAgent();
          await this.getUsersDataLocket();
        } else if (this.role <= 2) {
          await this.getUsersDataLocket();
          await this.getUserDataCurrent();
        }
      }
    }
  },
  methods: {
    async getTrxData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try{
        this.trx_model.from_date = this.$moment().format('YYYY-01-01');
        this.trx_model.to_date = this.$moment().format('YYYY-12-31');
        this.trx_model.status = '0';
        this.trx_model.type = '01';
        const queryParams = new URLSearchParams(this.trx_model).toString();
        let response = await GetRequest('transactions/count?' + queryParams);
        this.trxYear = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        return true;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        if (err === 'Token expired') {
          this.isRefresh = true;
          const refreshToken = await this.refreshToken();
          if (refreshToken) {
            await this.getTrxData();
            await this.getTrxDataMonth();
            await this.getTrxDataDay();
            if (this.role === 1) {
              await this.getUsersData();
              await this.getUsersDataAgent();
              await this.getUsersDataLocket();
            } else if (this.role <= 2) {
              await this.getUsersDataLocket();
              await this.getUserDataCurrent();
            }
          }
        } else {
          alert('Create user data failed ' + err.message);
        }
        return false;
      }
    },
    async getTrxDataMonth() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try{
        this.trx_model.from_date = this.$moment().format('YYYY-MM-01');
        this.trx_model.to_date = this.$moment().format('YYYY-MM-31');
        this.trx_model.status = '0';
        this.trx_model.type = '01';
        const queryParams = new URLSearchParams(this.trx_model).toString();
        let response = await GetRequest('transactions/count?' + queryParams);
        this.trxMonth = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        return true;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    async getTrxDataDay() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try{
        this.trx_model.from_date = this.$moment().format('YYYY-MM-DD');
        this.trx_model.to_date = this.$moment().format('YYYY-MM-DD');
        this.trx_model.status = '0';
        this.trx_model.type = '01';
        const queryParams = new URLSearchParams(this.trx_model).toString();
        let response = await GetRequest('transactions/count?' + queryParams);
        this.trxDay = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        return true;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    async getUsersData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try{
        let response = await GetRequest('users/count');
        this.totalUsers = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        return true;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    async getUsersDataAgent() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try{
        let response = await GetRequest('users/count?get_merchant=1');
        this.totalAgent = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        return true;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    async getUsersDataLocket() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try{
        let response = await GetRequest('users/count?get_locket=1');
        this.totalLocket = response.data;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        return true;
      }catch(err){
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create user data failed ' + err.message);
        return false;
      }
    },
    async refreshToken() {
      try {
        await RequestToken();
        await this.getTrxData();
      } catch (e) {
        alert('error load refresh token')
      }
    },
    async getUserDataCurrent() {
      this.current_balance = 0;
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        const response = await GetRequest('users/' + this.$store.getters.currentUser._id);
        this.overlay = this.$store.getters.isLoading;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        this.current_balance = response.data.current_balance;
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create data failed ' + e.message);
      }
    }
  }
}
</script>

<style scoped>

</style>
