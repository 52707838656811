import Axios from 'axios'

// const baseUrl = process.env.VUE_APP_BASE_URL;
export function initialize(store, router) {
    router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        const currentUser = store.state.currentUser;
        if (requiresAuth && !currentUser) {
            next('/login');
        } else if(to.path === '/login' && currentUser?.token) {
            next('/');
        } else {
            next();
        }
    });

    Axios.interceptors.response.use(null, async (error) => {
        if (error.response.data.message === 'Token expired' && error.response.data.status === 400) {
            // try {
            //     const params = {
            //         token: store.getters.currentUser.token,
            //         secretKey: 'secretkilatrefresh',
            //     };
            //     let response = await Axios.post(baseUrl + 'refresh-token', params);
            //     console.log('secretkilatrefresh', response.data);
            //     alert('success call refresh token')
            // } catch (e) {
            //     console.log(e.response);
            //     alert(e.response.data.message);
            // }
        }

        return Promise.reject(error);
    });

    if (store.getters.currentUser) {
        setAuthorization(store.getters.currentUser.token);
    }
}

export function setAuthorization(token) {
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Axios.defaults.headers.common["Content-Type"] = 'application/json';
    Axios.defaults.timeout = 15000;
    Axios.defaults.headers.common["Accept"] = 'application/json';
}
