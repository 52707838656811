import Axios from 'axios'
import store from '../index';

const baseUrl = process.env.VUE_APP_BASE_URL;
const baseUrlKilat = process.env.VUE_APP_KILAT_URL;
const headers = {
    headers: {
        Authorization: '',
    }
}

export async function PostRequest(url, params) {
    headers.headers.Authorization = 'Bearer ' + store.getters.currentToken;
    try {
        let response = await Axios.post(baseUrl + url, params, headers);
        return response.data;
    } catch (e) {
        throw e.response?.data?.message || 'Error on service';
    }
}

export async function PutRequest(url, params) {
    headers.headers.Authorization = 'Bearer ' + store.getters.currentToken;
    try {
        let response = await Axios.put(baseUrl + url, params, headers);
        return response.data;
    } catch (e) {
        throw e.response?.data?.message || 'Error on service';
    }
}

export async function GetRequest(url) {
    headers.headers.Authorization = 'Bearer ' + store.getters.currentToken;
    try {
        let response = await Axios.get(baseUrl + url, headers);
        return response.data;
    } catch (e) {
        // throw e.response?.data?.message || 'Error while getting data';
        throw e.response?.data?.message || 'Error while getting data';
    }
}

export async function DeleteRequest(url) {
    headers.headers.Authorization = 'Bearer ' + store.getters.currentToken;
    try {
        let response = await Axios.delete(baseUrl + url, headers);
        return response.data;
    } catch (e) {
        throw e.response?.data?.message || 'Error while getting data';
    }
}

export async function RequestToken() {
    try {
        const params = {
            token: store.getters.currentRefToken,
            secretKey: 'secretkilatrefresh',
        };
        let response = await Axios.post(baseUrl + 'refresh-token', params);
        await store.dispatch('ref_token', response.data);
        return response.data;
    } catch (e) {
        throw e.response?.data?.message || 'Error while request refresh token';
    }
}

export async function KilatRequest(url, params) {
    try {
        let response = await Axios.post(baseUrlKilat + url, params, headers);
        return response.data;
    } catch (e) {
        throw e.response?.data?.message || 'Error on service';
    }
}
