import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { getLocalUser, getToken, getRefToken } from './helpers/auth';


Vue.use(Vuex)

const user = getLocalUser();
const token = getToken();
const refToken = getRefToken();

export default new Vuex.Store({
  state: {
    status: '',
    currentUser: user,
    currentToken: token,
    currentRefToken: refToken,
    loading: false,
    auth_error: null,
    isLoggedIn: !!user,
    access_token: null,
    token_type : null,
    header: null,
    is_error: false,
  },
  mutations: {
    req_data(state) {
      state.loading = true;
      state.is_error = false;
    },
    req_success(state) {
      state.loading = false;
      state.is_error = false;
    },
    req_failed(state) {
      state.loading = false;
      state.is_error = true;
    },
    login(state) {
      state.loading = true;
      state.auth_error = null;
    },
    loginSuccess(state, payload) {
      state.auth_error = null;
      state.isLoggedIn = true;
      state.loading = false;
      state.access_token = payload.access_token;
      state.token_type = payload.token_type;
      state.currentUser = Object.assign({}, payload.data, {
        token: payload.tokenData.token,
      });
      state.currentToken = payload.tokenData.token;
      state.currentRefToken = payload.refToken.token;
      localStorage.setItem('userKilat', JSON.stringify(state.currentUser));
      localStorage.setItem('tokenKilat', payload.tokenData.token);
      localStorage.setItem('tokenRefKilat', payload.refToken.token);
    },
    loginFailed(state, payload) {
      state.loading = false
      if(payload.resp){
        state.auth_error = payload.resp;
      }else{
        state.auth_error = payload.error;
      }
    },
    logout(state) {
      localStorage.removeItem('userKilat');
      localStorage.removeItem('tokenKilat');
      localStorage.removeItem('tokenRefKilat');
      state.isLoggedIn = false;
      state.currentUser = null;
      state.currentToken = null;
      state.currentRefToken = null;
    },
    refToken(state, payload) {
      state.access_token = payload.token.token;
      state.currentToken = payload.token.token;
      state.currentRefToken = payload.refToken;
      localStorage.setItem('tokenKilat', state.currentToken);
      localStorage.setItem('tokenRefKilat', state.currentRefToken);
    },
  },
  actions: {
    req_data(context) {
      context.commit('req_data');
    },
    req_success(context) {
      context.commit('req_success');
    },
    req_failed(context) {
      context.commit('req_failed');
    },
    login(context) {
      context.commit('login');
    },
    ref_token(context, payload) {
      context.commit('refToken', payload);
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        // localStorage.removeItem('userKilat')
        // localStorage.removeItem('userKilat')
        delete axios.defaults.headers.common['Authorization']
        resolve()
        console(reject)
      })
    }
  },
  modules: {},
  getters: {
    isLoading(state) {
      return state.loading;
    },
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    access_token(state) {
      return state.access_token;
    },
    token_type(state) {
      return state.token_type;
    },
    currentUser(state) {
      return state.currentUser;
    },
    currentToken(state) {
      return state.currentToken;
    },
    currentRefToken(state) {
      return state.currentRefToken;
    },
    authError(state) {
      return state.auth_error;
    },
  }
})
