<template>
    <v-app>
      <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
        <v-row dense v-if="currentUser">
          <v-col>
            <v-row dense>
              <v-col>
                <img style="display: block; margin: auto"
                     :src="require('../../assets/imgs/logo.png')"
                     alt="alt">
              </v-col>
            </v-row>
            <v-row dense style="margin-top: -10px;">
              <v-col class="text-center">
                Login Sebagai
                <span v-if="$store.getters.currentUser.role.level === 1">
                  Super Admin
                </span>
                <span v-else-if="$store.getters.currentUser.role.level === 2">
                  Agen
                </span>
                <span v-else-if="$store.getters.currentUser.role.level === 3">
                  Loket
                </span>
                <h3> {{ $store.getters.currentUser.full_name }}</h3>
              </v-col>
            </v-row>
          </v-col>

        </v-row>

        <v-divider></v-divider>

        <v-list dense>
          <template v-for="item in items">
            <v-row
                v-if="item.heading"
                :key="item.heading"
                align="center"
            >
              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
              <v-col
                  cols="6"
                  class="text-center"
              >
                <a
                    href="#"
                    class="body-2 black--text"
                >EDIT</a>
              </v-col>
            </v-row>
            <v-list-group
                v-else-if="item.children"
                :key="item.text"
                v-model="item.model"
                :prepend-icon="item.model ? item.icon : item['icon-alt']"
                append-icon=""
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                  v-for="(child, i) in item.children"
                  :key="i"
                  link
                  :to="child.to"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
                v-else
                :key="item.text"
                link
                :to="item.to"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>


          <v-list-item link @click="logout">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

<!--      <app-bar></app-bar>-->

      <v-app-bar
          :clipped-left="$vuetify.breakpoint.lgAndUp"
          app
          color="blue darken-3"
          dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title
            style="width: 300px"
            class="ml-0 pl-4"
        >
        <span class="hidden-sm-and-down">
          {{ bank_name }}
        </span>
          <span class="hidden-sm-and-up">
         {{ $route.meta.title }}
        </span>

        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon fab @click="$router.go(-1)" class="hidden-sm-and-up mr-1">
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-app-bar>

      <v-main>
        <router-view/>
      </v-main>
    </v-app>
</template>

<script>
export default {
  name: 'Sidebar',
  components: {
    // AppBar
  },
  data: () => ({
    drawer: null,
    items: [],
    itemMenu: [],
    bank_name: 'Kilat PPOB',
  }),
  computed: {
    currentUser: function () {
      this.test();
      return this.$store.getters.currentUser
    },
  },
  mounted() {

  },
  methods: {
    test: function () {
      if (this.$store.getters.currentUser.bank_code === 'KLNPAY001') {
        this.bank_name = 'Klik n Pay';
      }
      if (this.$store.getters.currentUser.role.level === 1) {
        this.items = [
          {icon: 'mdi-view-dashboard', text: 'Dashboard', to: '/'},
          {icon: 'mdi-account-multiple', text: 'Users', to: '/users'},
          {icon: 'mdi-home-variant', text: 'Merchant', to: '/merchants'},
          {icon: 'mdi-home', text: 'Provider', to: '/banks'},
          {icon: 'mdi-cancel', text: 'Test Dev', to: '/testing'},
          {icon: 'mdi-cash', text: 'Selling Price', to: '/merchant-products'},
          {icon: 'mdi-history', text: 'Trx History', to: '/trx'},
          // {icon: 'mdi-cancel', text: 'Trx Failed', to: '/trx-fail'},
          {icon: 'mdi-cash', text: 'Deposit List', to: '/deposit'},
          {icon: 'mdi-newspaper', text: 'Category List', to: '/categories'},
          {icon: 'mdi-arrow-expand', text: 'Product List', to: '/products'},
          {icon: 'mdi-cogs', text: 'Profile', to: '/users/edit-current'},
        ]
      } else if (this.$store.getters.currentUser.role.level === 2) {
        this.items = [
          {icon: 'mdi-view-dashboard', text: 'Dashboard', to: '/'},
          {icon: 'mdi-account-multiple', text: 'Users', to: '/users'},
          {icon: 'mdi-home', text: 'Transfer Balance', to: '/tf-balance'},
          {icon: 'mdi-history', text: 'Trx History', to: '/trx'},
          {icon: 'mdi-cancel', text: 'Deposit List', to: '/deposit'},
          {icon: 'mdi-cash', text: 'Selling Price', to: '/merchant-products'},
          {icon: 'mdi-newspaper', text: 'Category List', to: '/categories'},
          {icon: 'mdi-arrow-expand', text: 'Product List', to: '/products'},
          {icon: 'mdi-cogs', text: 'Profile', to: '/users/edit-current'},
        ]
      } else if (this.$store.getters.currentUser.role.level === 3) {
        this.items = [

        ]
      }
    },
    logout: function () {
      this.items = []
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  }
};
</script>

