import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pages/home/Home.vue'
import { initialize } from '../store/helpers/general'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    component: () => import('../views/pages/login/Login')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/users',
    component: () => import('../views/pages/users/UserView'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'User',
        component: () => import('../views/pages/users/User'),
      },
      {
        path: 'new',
        name: 'UserNew',
        component: () => import('../views/pages/users/UserNew'),
      },
      {
        path: 'edit',
        name: 'UserEdit',
        component: () => import('../views/pages/users/UserEdit'),
      },
      {
        path: 'edit-current',
        name: 'UserEditCurrent',
        component: () => import('../views/pages/users/UserEditCurrent'),
      },
      {
        path: 'user-agent',
        name: 'UserLocketList',
        component: () => import('../views/pages/users/UserLocketList'),
      },
      {
        path: 'user-agent-new',
        name: 'UserNewLocket',
        component: () => import('../views/pages/users/UserNewLocket'),
      },
    ],
  },
  {
    path: '/merchants',
    component: () => import('../views/pages/merchants/Merchant'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'MerchantList',
        component: () => import('../views/pages/merchants/MerchantList'),
      },
      {
        path: 'new',
        name: 'MerchantNew',
        component: () => import('../views/pages/merchants/MerchantNew'),
      },
      {
        path: 'edit',
        name: 'MerchantEdit',
        component: () => import('../views/pages/merchants/MerchantEdit'),
      },
    ],
  },
  {
    path: '/banks',
    component: () => import('../views/pages/banks/Bank'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'BankList',
        component: () => import('../views/pages/banks/BankList'),
      },
      {
        path: 'new',
        name: 'BankNew',
        component: () => import('../views/pages/banks/BankNew'),
      },
      {
        path: 'edit',
        name: 'BankEdit',
        component: () => import('../views/pages/banks/BankEdit'),
      },
    ],
  },
  {
    path: '/categories',
    component: () => import('../views/pages/categories/Category'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'CategoryList',
        component: () => import('../views/pages/categories/CategoryList'),
      },
      {
        path: 'new',
        name: 'CategoryNew',
        component: () => import('../views/pages/categories/CategoryNew'),
      },
      {
        path: 'edit',
        name: 'CategoryEdit',
        component: () => import('../views/pages/categories/CategoryEdit'),
      },
    ],
  },
  {
    path: '/products',
    component: () => import('../views/pages/products/Product'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'ProductList',
        component: () => import('../views/pages/products/ProductList'),
      },
      {
        path: 'new',
        name: 'ProductNew',
        component: () => import('../views/pages/products/ProductNew'),
      },
      {
        path: 'edit',
        name: 'ProductEdit',
        component: () => import('../views/pages/products/ProductEdit'),
      },
    ],
  },
  {
    path: '/merchant-products',
    component: () => import('../views/pages/merchant-products/MerchantProduct'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'MerchantProductList',
        component: () => import('../views/pages/merchant-products/MerchantProductList'),
      },
      {
        path: 'new',
        name: 'MerchantProductNew',
        component: () => import('../views/pages/merchant-products/MerchantProductNew'),
      },
      {
        path: 'edit',
        name: 'MerchantProductEdit',
        component: () => import('../views/pages/merchant-products/MerchantProductEdit'),
      },
    ],
  },
  {
    path: '/testing',
    component: () => import('../views/pages/testing/Testing'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'TestingForm',
        component: () => import('../views/pages/testing/TestingForm'),
      },
    ],
  },
  {
    path: '/trx',
    component: () => import('../views/pages/trx/Trx'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'TrxList',
        component: () => import('../views/pages/trx/TrxList'),
      },
      // {
      //   path: 'agent',
      //   name: 'TrxHistListAgent',
      //   component: () => import('../views/page/admin/trx-hist/TrxHistListAgent'),
      // },
      // {
      //   path: 'fail',
      //   name: 'TrxHistListFail',
      //   component: () => import('../views/page/admin/trx-hist/TrxHistListFail'),
      // },
    ],
  },
  {
    path: '/deposit',
    component: () => import('../views/pages/deposit/Deposit'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'DepositList',
        component: () => import('../views/pages/deposit/DepositList'),
      },
      {
        path: 'new',
        name: 'DepositNew',
        component: () => import('../views/pages/deposit/DepositNew'),
      },
      {
        path: 'edit',
        name: 'DepositEdit',
        component: () => import('../views/pages/deposit/DepositEdit'),
      },
      // {
      //   path: 'fail',
      //   name: 'TrxHistListFail',
      //   component: () => import('../views/page/admin/trx-hist/TrxHistListFail'),
      // },
    ],
  },
  {
    path: '/tf-balance',
    component: () => import('../views/pages/tf-balance/TfBalance'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'TfBalanceNew',
        component: () => import('../views/pages/tf-balance/TfBalanceNew'),
      },
    ],
  },
  // {
  //   path: '/trx-fail',
  //   component: () => import('../views/page/admin/trx-hist/TrxHist'),
  //   meta: {
  //     requiresAuth: true
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'TrxHistListFail',
  //       component: () => import('../views/page/admin/trx-hist/TrxHistListFail'),
  //     },
  //   ],
  // },
  // {
  //   path: '/deposit',
  //   component: () => import('../views/page/admin/deposit/Deposit'),
  //   meta: {
  //     requiresAuth: true
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'DepositList',
  //       component: () => import('../views/page/admin/deposit/DepositList'),
  //     },
  //     {
  //       path: 'detail',
  //       name: 'DepositDetail',
  //       component: () => import('../views/page/admin/deposit/DepositDetail'),
  //     },
  //   ],
  // },
  // {
  //   path: '/categories',
  //   component: () => import('../views/page/admin/category/Category'),
  //   meta: {
  //     requiresAuth: true
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'CategoryList',
  //       component: () => import('../views/page/admin/category/CategoryList'),
  //     },
  //     {
  //       path: 'new',
  //       name: 'CategoryNew',
  //       component: () => import('../views/page/admin/category/CategoryNew'),
  //     },
  //     {
  //       path: 'edit',
  //       name: 'CategoryEdit',
  //       component: () => import('../views/page/admin/category/CategoryEdit'),
  //     },
  //   ],
  // },
  // {
  //   path: '/products',
  //   component: () => import('../views/page/admin/product/Product'),
  //   meta: {
  //     requiresAuth: true
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'ProductList',
  //       component: () => import('../views/page/admin/product/ProductList'),
  //     },
  //     {
  //       path: 'new',
  //       name: 'ProductNew',
  //       component: () => import('../views/page/admin/product/ProductNew'),
  //     },
  //     {
  //       path: 'edit',
  //       name: 'ProductEdit',
  //       component: () => import('../views/page/admin/product/ProductEdit'),
  //     },
  //   ],
  // },
  // {
  //   path: '/price-list',
  //   component: () => import('../views/page/admin/price-list/PriceList'),
  //   meta: {
  //     requiresAuth: true
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'PriceListList',
  //       component: () => import('../views/page/admin/price-list/PriceListList'),
  //     },
  //   ],
  // },
]

const router = new VueRouter({
  routes
})

initialize(store, router)

export default router
