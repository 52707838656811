<template>
  <v-app id="inspire" v-if="isLoggedIn && $route.name !== 'PrintPage' && $route.name !== 'Child'">
    <sidebar></sidebar>
  </v-app>

  <v-app v-else>
    <router-view/>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import Sidebar from './components/layout/Sidebar';

export default {
  name: 'App',

  components: {
    Sidebar,
  },

  data: () => ({
    //
  }),
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>
